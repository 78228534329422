import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';

import { setVerification } from '../../actions/giftcard';
import history from '../../history';
import { setRequestedPath } from '../../helpers/userStorage';
import { urlPrefix } from '../../helpers';
import { validateEmail, validatePhone } from '../../helpers/validator';
import {
  AUTHORIZATION_SCHEMES,
  GIFTCARD_PATH,
  NOT_FOUND_PATH,
  whiteLabelPath,
} from '../../constants';
import TextInput from '../../components/TextInput';
import PhoneNumberInput from '../../components/PhoneNumberInput';
import Page from '../../components/Page';
import Field from '../../components/FormField';
import Button from '../../components/Clickable';
import {
  giftcardMessages,
  footerMessages,
  giftcardFormMessages as messages,
} from '../../defineMessages';
import s from './VerifyGiftcardOwner.scss';
import { redirectTo } from '../../actions/config';
import LanguageSwitcher from '../../components/LanguageSwitcher/LanguageSwitcher';
import { setPath } from '../../actions/intl';

const verificationFields = {
  byemail: {
    label: 'ownerEmail',
    name: 'email',
    placeholder: 'ownerEmailPlaceholder',
    component: TextInput,
    type: 'email',
  },
  bysms: {
    label: 'ownerPhone',
    name: 'phoneNumber',
    placeholder: 'ownerPhonePlaceholder',
    component: PhoneNumberInput,
    type: 'tel',
  },
  bypin: {
    label: 'ownerPin',
    name: 'pin',
    placeholder: 'ownerPinPlaceholder',
    component: TextInput,
    type: 'number',
  },
};

class VerifyGiftcardOwner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: undefined,
      phoneNumber: undefined,
      pin: undefined,
      hasError: false,
    };
  }

  componentDidMount() {
    const {
      giftcard: {
        authorizationScheme,
        verification: { pin },
        errors,
      },
    } = this.props;
    if (!errors?.length) {
      if (authorizationScheme === AUTHORIZATION_SCHEMES.byAccountId) {
        this.verifyAuthentication();
      } else if (
        authorizationScheme === AUTHORIZATION_SCHEMES.noAuthorization
      ) {
        this.redirectToDownload();
        // if pathname has pin and authScheme byPin go to next step of redeem flow
      } else if (authorizationScheme === AUTHORIZATION_SCHEMES.byPin && pin) {
        this.redirectToDownload();
        // if pathname has pin and authScheme is NOT byPin go to page not found
      } else if (authorizationScheme !== AUTHORIZATION_SCHEMES.byPin && pin) {
        this.redirectToPageNotFound();
      }
    }
  }

  redirectToPageNotFound = async () => {
    const { dispatch } = this.props;
    await dispatch(redirectTo({ pathname: NOT_FOUND_PATH }));
  };

  redirectToDownload = () => {
    const {
      giftcard: { exportType },
      redirectUri,
    } = this.props;
    history.push(redirectUri[exportType] || redirectUri.default);
  };

  submitVerification = (event, name, type) => {
    event.preventDefault();
    const {
      dispatch,
      giftcard: { token, exportType },
    } = this.props;

    const value = this.state[name];
    let isValid = value && value !== '';

    if (type === 'email') {
      isValid = validateEmail(value);
    } else if (type === 'tel') {
      isValid = validatePhone(value);
    }

    if (isValid) {
      dispatch(
        setVerification({
          name,
          value,
          token,
          exportType,
        }),
      );
      this.redirectToDownload();
    } else {
      this.setState({ hasError: true });
    }
  };

  verifyAuthentication = () => {
    const {
      loggedinUser: { user },
      dispatch,
      giftcard: { token, exportType },
    } = this.props;
    const { pathname } = this.context;

    if (!user) {
      setRequestedPath({ pathname });
      const userManager = require('../../helpers/userManager'); // eslint-disable-line global-require
      userManager.default.signinRedirect();
    } else {
      dispatch(
        setVerification({
          name: 'isByJwt',
          value: true,
          token,
          exportType,
        }),
      );
      this.redirectToDownload();
    }
  };

  render() {
    const {
      giftcard: {
        authorizationScheme,
        loading,
        verification: { pin },
        errors,
      },
      intl: { formatMessage },
      dispatch,
    } = this.props;

    const scheme = authorizationScheme.toLowerCase();
    const verificationField = verificationFields[scheme];
    // if pathname has pin dont render the form
    if (errors?.length || (verificationField && !pin)) {
      const { component, type, name, label, placeholder } = verificationField;

      return (
        <div className={s.verify}>
          <div className={s.lanDiv}>
            <LanguageSwitcher
              labelRootClass={s.lanLabel}
              selectRootClass={s.lanSelect}
              label={formatMessage({ ...footerMessages.language })}
              onChange={value => dispatch(setPath({ locale: value }))}
            />
          </div>
          <h1>
            {formatMessage({
              ...giftcardMessages.header,
            })}
          </h1>
          <strong>
            {formatMessage({
              ...giftcardMessages.subtitle,
            })}
          </strong>
          <p>
            {formatMessage({
              ...giftcardMessages[scheme],
            })}
          </p>
          <form
            noValidate
            onSubmit={event => this.submitVerification(event, name, type)}
          >
            <Field
              input={component}
              type={type}
              name={name}
              label={formatMessage({
                ...messages[label],
              })}
              showLabel
              required
              value={this.state[name] || ''}
              onChange={value => this.setState({ [name]: value })}
              hasError={
                this.state && this.state.hasError
                  ? { message: formatMessage({ ...messages.inputError }) }
                  : false
              }
              placeholder={formatMessage({ ...messages[placeholder] })}
            />
            <div className={s.submit}>
              <Button
                fluid
                primary
                type="submit"
                label={formatMessage({ ...messages.verifyOwner })}
                disabled={loading}
              />
            </div>
          </form>
        </div>
      );
    }

    return <Page loading />;
  }
}

VerifyGiftcardOwner.propTypes = {
  giftcard: PropTypes.shape().isRequired,
  loggedinUser: PropTypes.shape().isRequired,
  intl: PropTypes.shape().isRequired,
  redirectUri: PropTypes.shape().isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapState = state => {
  const {
    intl: { locale, countryCode, currency },
    config: {
      salesChannel: { id, showWhiteLabelLogoUrl },
    },
  } = state;

  const giftCardPath = showWhiteLabelLogoUrl
    ? whiteLabelPath(id, GIFTCARD_PATH)
    : GIFTCARD_PATH;
  return {
    giftcard: state.giftcard,
    loggedinUser: state.loggedinUser,
    redirectUri: {
      bundle: urlPrefix('/giftcard/bundle', { countryCode, locale, currency }),
      default: urlPrefix(giftCardPath, { countryCode, locale, currency }),
    },
  };
};

export default compose(
  withStyles(s),
  injectIntl,
  connect(mapState),
)(VerifyGiftcardOwner);
