export function validateEmail(email) {
  // eslint-disable-next-line no-useless-escape
  const expression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return expression.test(String(email).toLowerCase());
}

export function validatePhone(phone) {
  const expression = /^(00|\+)\d{1,28}$/;

  return expression.test(String(phone));
}
