import { UserManager } from 'oidc-client';

const createUserManager = ({
  authHost,
  selfHost,
  clientSecret,
  addUserRegistrationFlow,
}) => {
  const userManagerConfig = {
    client_id: 'salesfront-mainsite-pkce-client',
    // Cannot be changed. Auth service will only redirect to the routes below
    // Must match exactly. No query strings.
    redirect_uri: `${selfHost}/signin-oidc`,
    post_logout_redirect_uri: `${selfHost}/signout-callback-oidc`,
    response_type: 'code',
    scope: 'openid profile AuthApi',
    authority: authHost,
    loadUserInfo: true,
    automaticSilentRenew: true,
    silent_redirect_uri: `${selfHost}/signin-oidc`,
    // Make sure user info is not retrieved when accessToken in express session is not cleared (post_logout_redirect_uri not called from auth)
    revokeAccessTokenOnSignout: true,
    client_secret: clientSecret,
  };

  if (addUserRegistrationFlow) {
    userManagerConfig.extraQueryParams = {
      useFlow: 'UserRegistration',
    };
  }

  return new UserManager(userManagerConfig);
};

export default createUserManager;
